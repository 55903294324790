import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import SwiperCore, { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Card from "../../Molecules/Card";
import Button from "../../Atoms/Button";
import Link from "../../Atoms/Link";
import RichText from "../../Atoms/RichText";

import {
  mediaquery,
  colors,
  rem,
  scaleSpacing,
  hexRgba,
  breakpoints,
  zIndexes,
} from "../../../../styles/theme";
import {
  OswaldOverline1,
  OswaldOverline2,
  NotoLarge,
} from "../../Atoms/Typography";

SwiperCore.use([Navigation, Scrollbar, A11y]);

const swiperTheme = type => {
  switch (type) {
    case "small":
      return {
        title: css`
          color: ${colors.white};
        `,
        button: {
          backgroundColor: hexRgba(colors.white, 0.3),
          color: colors.white,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.lightgrey, 0.1),
          color: hexRgba(colors.white, 1),
        },
        container: {
          paddingTop: scaleSpacing(8),
          paddingBottom: scaleSpacing(10),
        },
        componentContainer: {
          backgroundColor: "transparent",
          paddingTop: scaleSpacing(0),
        },
      };

    case "dark":
      return {
        title: css`
          color: ${colors.white};
        `,
        button: {
          backgroundColor: hexRgba(colors.white, 0.3),
          color: colors.white,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.lightgrey, 0.1),
          color: hexRgba(colors.white, 1),
        },
        container: {
          paddingTop: scaleSpacing(6),
          paddingBottom: scaleSpacing(0),
          large: {
            paddingTop: scaleSpacing(6),
            paddingBottom: scaleSpacing(0),
          },
        },
        componentContainer: {
          backgroundColor: colors.black,
          paddingTop: scaleSpacing(16),
          paddingBottom: scaleSpacing(12),
          large: {
            paddingTop: scaleSpacing(26),
            paddingBottom: scaleSpacing(25),
          },
        },
      };

    case "white":
      return {
        title: css`
          color: ${colors.black};
        `,
        button: {
          backgroundColor: hexRgba(colors.white, 0.3),
          color: colors.white,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.lightgrey, 0.1),
          color: hexRgba(colors.white, 1),
        },
        container: {
          paddingTop: scaleSpacing(8),
          paddingBottom: 0,
        },
        componentContainer: {
          backgroundColor: colors.white,
          paddingTop: scaleSpacing(0),
        },
      };

    case "Portrait":
      return {
        title: css`
          color: ${colors.white};
        `,
        button: {
          backgroundColor: hexRgba(colors.white, 0.3),
          color: colors.white,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.lightgrey, 0.1),
          color: hexRgba(colors.white, 1),
        },
        container: {
          paddingTop: scaleSpacing(8),
          paddingBottom: scaleSpacing(0),
          large: {
            paddingTop: scaleSpacing(7),
            paddingBottom: scaleSpacing(0),
          },
        },
        componentContainer: {
          backgroundColor: colors.black,
          paddingTop: scaleSpacing(10),
          paddingBottom: scaleSpacing(10),
          large: {
            paddingTop: scaleSpacing(26),
            paddingBottom: scaleSpacing(25),
          },
        },
      };

    case "Podcast":
      return {
        title: css`
          color: ${colors.black};
        `,
        button: {
          backgroundColor: hexRgba(colors.darkgrey1, 0.3),
          color: colors.white,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.lightgrey2, 0.5),
          color: hexRgba(colors.darkgrey1, 0.3),
        },
        container: {
          paddingTop: scaleSpacing(8),
          paddingBottom: scaleSpacing(0),
          large: {
            paddingTop: scaleSpacing(6),
            paddingBottom: scaleSpacing(0),
          },
        },
        componentContainer: {
          backgroundColor: colors.white,
          paddingTop: scaleSpacing(0),
          paddingBottom: scaleSpacing(16),
          large: {
            paddingTop: scaleSpacing(0),
            paddingBottom: scaleSpacing(16),
          },
        },
      };

    case "AuthorDark":
      return {
        title: css`
          color: ${colors.white};
        `,
        button: {
          backgroundColor: hexRgba(colors.white, 0.3),
          color: colors.white,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.lightgrey, 0.1),
          color: hexRgba(colors.white, 1),
        },
        container: {
          paddingTop: scaleSpacing(6),
          paddingBottom: scaleSpacing(0),
          large: {
            paddingTop: scaleSpacing(18),
            paddingBottom: scaleSpacing(0),
          },
        },
        imageContainer: {
          backgroundColor: "transparent",
        },
        componentContainer: {
          backgroundColor: colors.darkgrey2,
          paddingTop: scaleSpacing(14),
          paddingBottom: scaleSpacing(14),
          large: {
            paddingTop: scaleSpacing(17),
            paddingBottom: scaleSpacing(20),
          },
        },
      };

    case "Magazine":
      return {
        title: css`
          color: ${colors.black};
          -webkit-text-stroke: 1px ${colors.black};
        `,
        button: {
          backgroundColor: hexRgba(colors.darkgrey1, 0.3),
          color: colors.white,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.lightgrey, 0.1),
          color: hexRgba(colors.white, 1),
        },
        container: {
          paddingTop: scaleSpacing(6),
          paddingBottom: scaleSpacing(0),
          large: {
            paddingTop: scaleSpacing(16),
            paddingBottom: scaleSpacing(0),
          },
        },
        componentContainer: {
          backgroundColor: colors.white,
          paddingTop: scaleSpacing(16),
          paddingBottom: scaleSpacing(20),
          large: {
            paddingTop: scaleSpacing(7),
            paddingBottom: scaleSpacing(22),
          },
        },
      };

    case "InTheaters":
      return {
        title: css`
          color: ${colors.white};
        `,
        button: {
          display: "none",
        },
        container: {
          paddingTop: scaleSpacing(6),
          paddingBottom: scaleSpacing(0),
          large: {
            paddingTop: scaleSpacing(6),
            paddingBottom: scaleSpacing(0),
          },
        },
        componentContainer: {
          backgroundColor: colors.darkgrey2,
          paddingTop: scaleSpacing(10),
          paddingBottom: scaleSpacing(10),
          large: {
            paddingTop: scaleSpacing(16),
            paddingBottom: scaleSpacing(16),
          },
        },
      };

    default:
      return {
        title: css`
          color: ${colors.black};
        `,
        button: {
          backgroundColor: colors.lightgrey,
          color: colors.black,
        },
        scrollbar: {
          backgroundColor: hexRgba(colors.mediumgrey3, 0.1),
          color: colors.black,
        },
        container: {
          paddingTop: scaleSpacing(14),
          paddingBottom: scaleSpacing(14),
        },
        componentContainer: {
          backgroundColor: "transparent",
          paddingTop: scaleSpacing(0),
        },
      };
  }
};
const SwiperCarouselContainer = styled.div`
  background-color: ${({ theme }) => theme.componentContainer.backgroundColor};
  padding-top: ${({ theme }) => theme.componentContainer.paddingTop};
  padding-bottom: ${({ theme }) => theme.componentContainer.paddingBottom};

  ${({ theme }) =>
    css`
      ${mediaquery.sm(css`
        padding-top: ${theme.componentContainer.large?.paddingTop};
        padding-bottom: ${theme.componentContainer.large?.paddingBottom};
      `)}
    `}

  ${({ theme }) =>
    theme?.imageContainer?.backgroundColor &&
    css`
      .gatsby-image-wrapper {
        background: ${theme.imageContainer.backgroundColor};
      }
    `};
`;

const Top = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 0 var(--external-gutter);
  flex-direction: column;

  ${({ theme }) => theme.top};

  ${mediaquery.sm(css`
    flex-direction: row;
    align-items: center;
  `)};

  ${({ type }) =>
    type === "Magazine" &&
    css`
      align-items: center;
      ${mediaquery.sm(css`
        margin: ${scaleSpacing(6)} var(--external-gutter) 0
          var(--external-gutter);
        justify-content: center;
        flex-direction: column;
      `)}
    `}
`;

const Title = styled.h2`
  ${OswaldOverline2};
  text-transform: uppercase;

  ${mediaquery.sm(css`
    ${OswaldOverline1};
  `)};

  ${({ theme }) => theme.title};
`;

const MoreLink = styled(Link)`
  ${NotoLarge};
  line-height: ${rem(16)};
  margin: ${scaleSpacing(4)} 0 0 0;

  ${({ theme }) => theme.title};
  ${mediaquery.sm(css`
    margin: 0 0 0 ${scaleSpacing(10)};
  `)};
`;

const carouselButton = theme => css`
  position: relative;
  padding: 0;
  width: ${scaleSpacing(8)};
  height: ${scaleSpacing(10)};
  border-radius: ${scaleSpacing(7)};
  background: ${theme.button.backgroundColor};
  color: ${theme.button.color};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  &:after {
    font-size: ${scaleSpacing(2)};
    font-weight: 600;
  }
`;

const Controls = styled.div`
  display: none;
  position: absolute;
  height: ${rem(36)};
  top: 0;
  right: 0;
  z-index: ${zIndexes.middle};

  ${mediaquery.sm(css`
    display: flex;
  `)};

  .swiper-button-prev,
  .swiper-button-next {
    ${({ theme }) => carouselButton(theme)};
    outline: none;
  }

  .swiper-button-prev {
    margin-right: ${scaleSpacing(7)};
  }

  .swiper-button-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .swiper-container {
    ${({ theme }) => theme.container};
    padding-top: ${({ theme }) => theme.container.paddingTop};
    padding-bottom: ${({ theme }) => theme.container.paddingBottom};

    ${({ theme }) =>
      css`
        ${mediaquery.sm(css`
          padding-top: ${theme.container.large?.paddingTop};
          padding-bottom: ${theme.container.large?.paddingBottom};
        `)}
      `}

    ${({ type }) =>
      type === "InTheaters" &&
      css`
        .parentWrapper {
          ${mediaquery.xxl(css`
            width: 302px;
          `)};
        }

        .swiper-slide {
          ${mediaquery.sm(css`
            margin-right: ${scaleSpacing(10)} !important;
          `)};
        }
      `};
  }

  .swiper-slide {
    flex-shrink: 1;
  }

  @media (hover: hover) and (pointer: fine) {
    .swiper-wrapper {
      & > * {
        transition: opacity 300ms ease-out;
      }
      &:hover > * {
        opacity: 0.8;
      }
      &:hover > *:hover {
        opacity: 1;
      }
    }
  }
`;

const CarouselScrollbar = styled.div`
  position: relative;
  margin-top: ${scaleSpacing(8)};
  height: ${scaleSpacing(1)};
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: ${scaleSpacing(15)};
  margin-left: var(--external-gutter);
  margin-right: var(--external-gutter);
  cursor: grab;
  display: none;

  ${mediaquery.sm(css`
    display: block;
  `)};

  .swiper-scrollbar-drag {
    background-color: ${({ theme }) => theme.color};
    border-radius: ${scaleSpacing(15)};
  }
`;

const SwiperCarousel = ({
  id,
  title,
  type,
  animate,
  showButton,
  cards,
  imageRatio,
  onClickCard,
  noScrollbar,
  link,
  linkLabel,
  renderCard,
  renderTitle,
  renderViewAllLink,
  authors,
  magazines,
}) => {
  const slides = cards || authors || magazines;

  const classNames = {
    prev: `swiper-button-prev-${id}`,
    next: `swiper-button-next-${id}`,
  };
  const carouselTopRef = useRef();
  const carouselTheme = swiperTheme(type);

  return (
    <SwiperCarouselContainer id={id} theme={carouselTheme} type={type}>
      <Top type={type} theme={carouselTheme} ref={carouselTopRef}>
        {!!title && (
          <Title theme={carouselTheme} type={type}>
            {typeof title === "string" ? title : <RichText data={title} />}
          </Title>
        )}

        {linkLabel && link?.url && (
          <MoreLink link={link} theme={carouselTheme}>
            {linkLabel}
          </MoreLink>
        )}

        {!!renderTitle && renderTitle(type)}
        {!!renderViewAllLink && renderViewAllLink(type)}
        <Controls theme={carouselTheme}>
          <Button className={`${classNames.prev} swiper-button-prev`} />
          <Button className={`${classNames.next} swiper-button-next`} />
        </Controls>
      </Top>
      <CarouselContainer theme={carouselTheme} type={type}>
        <Swiper
          grabCursor
          mousewheel
          slidesPerView="auto"
          allowSlideNext
          allowSlidePrev
          allowTouchMove
          slidesOffsetBefore={scaleSpacing(6, true)}
          slidesOffsetAfter={scaleSpacing(6, true)}
          spaceBetween={scaleSpacing(4, true)}
          breakpoints={{
            [breakpoints.sm]: {
              spaceBetween:
                type === "small"
                  ? scaleSpacing(6, true)
                  : scaleSpacing(4, true),
              freeMode: true,
              slidesOffsetBefore: scaleSpacing(14, true),
              slidesOffsetAfter: scaleSpacing(14, true),
              allowSlideNext: true,
              allowSlidePrev: true,
              allowTouchMove: true,
            },
            [breakpoints.xl]: {
              spaceBetween:
                type === "small"
                  ? scaleSpacing(6, true)
                  : scaleSpacing(4, true),
              slidesOffsetBefore: scaleSpacing(14, true),
              slidesOffsetAfter: scaleSpacing(14, true),
            },
          }}
          wrapperTag="ul"
          navigation={{
            nextEl: `.${classNames.next}`,
            prevEl: `.${classNames.prev}`,
          }}
          scrollbar={{
            el: noScrollbar ? null : `.swiper-scrollbar-${id}`,
            draggable: true,
            dragClass: `swiper-scrollbar-drag swiper-scrollbar-drag-${id}`,
          }}
        >
          {slides.map((styles, index) => {
            return (
              <SwiperSlide key={index} tag="li">
                {renderCard ? (
                  renderCard(slides[index], animate, styles)
                ) : (
                  <Card
                    {...slides[index]}
                    onClickDetails={onClickCard}
                    type={type}
                    animate={animate}
                    showButton={showButton}
                    animatedStyles={styles}
                    imageRatio={imageRatio}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        {!noScrollbar && (
          <CarouselScrollbar
            className={`swiper-scrollbar-${id}`}
            theme={carouselTheme.scrollbar}
          />
        )}
      </CarouselContainer>
    </SwiperCarouselContainer>
  );
};

export default SwiperCarousel;

SwiperCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
  link: PropTypes.object,
  linkLabel: PropTypes.string,
  animate: PropTypes.bool,
  showButton: PropTypes.bool,
  reveal: PropTypes.bool,
  imageRatio: PropTypes.number,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      topic: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      ctaLabel: PropTypes.string,
      ctaLink: PropTypes.object,
      ctaDetailPopin: PropTypes.array,
      detailsLabel: PropTypes.string,
      details: PropTypes.array,
      onClickDetaill: PropTypes.func,
      credits: PropTypes.string,
      image: PropTypes.object,
    })
  ),
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      authorName: PropTypes.string,
      link: PropTypes.object,
      image: PropTypes.object,
    })
  ),
  magazines: PropTypes.arrayOf(
    PropTypes.shape({
      pdfTitle: PropTypes.string,
      pdfDescription: PropTypes.string,
      pdfDownloadLink: PropTypes.object,
      image: PropTypes.object,
    })
  ),
  onClickCard: PropTypes.func,
  renderTitle: PropTypes.func,
  renderViewAllLink: PropTypes.func,
  renderCard: PropTypes.func,
  noScrollbar: PropTypes.bool,
};

SwiperCarousel.defaultProps = {
  noScrollbar: false,
  animate: false,
};
